import React from 'react';
import ListItem from './ListItem/ListItem';
import { menuItems } from '../../data/menu';
import './MainMenu.css';

class MainMenu extends React.Component {
  componentDidMount() {
    const hamurgerIcon = document.querySelector(".mainMenu__mobileMenu");
    const hamburgerTop = document.querySelector(".mobileMenu__topLine");
    const hamburgerMiddle = document.querySelector(".mobileMenu__middleLine");
    const hamburgerBottom = document.querySelector(".mobileMenu__bottomLine")
    const menuList = document.querySelector(".mainMenu__listWrapper");
    hamurgerIcon.addEventListener("click", function(e) {
      e.preventDefault();
      hamburgerTop.classList.toggle("mobileMenu__topLine--active");
      hamburgerMiddle.classList.toggle("mobileMenu__middleLine--active");
      hamburgerBottom.classList.toggle("mobileMenu__bottomLine--active");

      if (!menuList.classList.contains('mainMenu__listWrapper--appear')) {
        menuList.classList.add('mainMenu__listWrapper--appear');
        menuList.style.height = 'auto';

        let height = menuList.clientHeight + "px";

        menuList.style.height = '0px';

        setTimeout(function () {
          menuList.style.height = height;
        }, 0);
    } else {
      menuList.style.height = '0px';

      menuList.addEventListener('transitionend', function () {
        menuList.classList.remove('mainMenu__listWrapper--appear');
        }, {
            once: true
        });
    }
  }, false); 
  }
  render() {
    return(
    <>
      <div className='mainMenu__mobileMenu'>
        <div className="mobileMenu__topLine"></div>
        <div className="mobileMenu__middleLine"></div>
        <div className="mobileMenu__bottomLine"></div>
      </div>
      <ul className='mainMenu__listWrapper'>
        {menuItems.map((item) => (
          <ListItem
            key={item.title}
            title={item.title}
            link={item.link}
          />
        ))}
      </ul>
    </>
    )
  }
}

export default MainMenu;