import React from 'react';
import { Link } from 'gatsby';
import './ListItem.css';

const ListItem = ({title, link}) => (
    <li className='listWrapper__listItem'>
        <Link to={`${link}`} >
            { title }
        </Link>
    </li>
);

export default ListItem;