export const menuItems = [
    {
        title: 'Główna',
        link: '/',
    },
    {
        title: 'Blog',
        link: 'blog',
    },
    {
        title: 'O mnie',
        link: '/jillian',
    },
]