import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header/Header";
import "./layout.css";
import Footer from "./Footer/Footer";
import MainMenu from "./MainMenu/MainMenu";



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      blog {
        blogInfoes {
          header {
            handle
          }
          copyright
          title
        }
      }
    }
  `)

  return (
    <>
      <Header 
        siteTitle={data.blog.blogInfoes[0].title} 
        banner={data.blog.blogInfoes[0].header.handle}
      />
      <MainMenu />
      <main>{children}</main>
      <Footer
          copyright={ data.blog.blogInfoes[0].copyright }
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
