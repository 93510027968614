import React from 'react';
import './Footer.css';

const Footer = () => (
    <footer>
      <div className="footer__author">Jillian © {new Date().getFullYear()}</div> 
      <div className="footer__dev">Design&Create by <a href='http://www.cv.dariusz-mazur.pl' target='_blank' rel="noopener noreferrer">Gacek</a></div>
    </footer>
  );

  export default Footer;