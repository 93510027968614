import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './Header.css';
import Logotyp from '../../assets/images/logotyp.svg';

const Header = ({ siteTitle, banner }) => (
  <header style={{background:`url(https://media.graphcms.com/${banner}) center/cover no-repeat`}} >
      <h2 className='header__h2'>Nie znam się, to...</h2>
      <h1 className='header__h1'>
        <Link
          to="/"
        >
          <img src={Logotyp} alt='siewypowiem' className='header__logotyp' />
        </Link>
      </h1>
      <h2 className='header__h2 header__author'>by Jillian</h2>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
